import { StylesConfig } from 'react-select';

import { tVariants } from '@blockworks/ui/system';

const multiSelectStyles = tVariants({
    base: 'w-full',
    slots: {
        control:
            'focus-within:!ring-primary-focus focus-within:!ring-1 hover:!border-surface-interact !cursor-text !py-0 !rounded-md',
        input: 'focus:!ring-0 focus:!outline-none [&>input]:!outline-none [&>input]:!ring-0 !cursor-text !py-0 !m-0',
        valueContainer: '!p-0 !px-2 flex items-center',
        placeholder: '!mx-0',
        indicatorsContainer: '!h-full',
        button: 'cursor-pointer',
        multiValue: '!rounded-md flex first-of-type:!ml-0',
        multiValueRemove: '!rounded-r-md !rounded-l-none',
        multiValueLabel: '!rounded-md self-center',
    },
    variants: {
        size: {
            sm: {
                control: '!h-8 !min-h-8 !max-h-8 !text-xs',
                multiValue: '!h-6 !min-h-6 !max-h-6',
                multiValueLabel: '!px-1.5',
                input: '!text-xs',
                valueContainer: '!text-xs',
            },
            md: {
                control: '!h-10 !min-h-10 !max-h-10 !text-sm',
                multiValue: '!h-7 !min-h-7 !max-h-7',
                multiValueLabel: '!px-2',
                multiValueRemove: '!px-1.5',
                input: '!text-sm',
                valueContainer: '!text-sm',
            },
            lg: {
                control: '!h-12 !min-h-12 !max-h-12 !text-md',
                multiValue: '!h-8 !min-h-8 !max-h-8',
                multiValueLabel: '!px-2.5',
                multiValueRemove: '!px-2',
                input: '!text-md',
                valueContainer: '!h-12 !text-md',
            },
        },
    },
});

type ItemWithColor = {
    [key: string]: string;
    color: string;
};

const overrideStyles: StylesConfig<ItemWithColor> = {
    control: (styles, state) => ({
        ...styles,
        borderColor: state.isFocused ? 'border-surface-interact' : 'border-surface-divider',
        color: state.isFocused ? 'text-content' : 'text-content-muted',
    }),
    multiValue: (styles, state) => {
        const data = state.data as ItemWithColor;
        const hasColor = !!data.color;
        if (!hasColor) return styles;
        return {
            ...styles,
            backgroundColor: `#${data.color}30`,
        };
    },
    multiValueRemove: (styles, state) => {
        const data = state.data as ItemWithColor;
        const hasColor = !!data.color;
        if (!hasColor) return styles;
        return {
            ...styles,
            backgroundColor: `#${data.color}50`,
            color: 'white',
        };
    },
    option: (styles, state) => {
        const data = state.data as ItemWithColor;
        const hasColor = !!data.color;
        if (!hasColor) return styles;
        return {
            ...styles,
            fontSize: 12,
            cursor: 'pointer',
            pointerEvents: 'all',
            color: `#${data.color}90`,
        };
    },
};

export { multiSelectStyles, overrideStyles };

import { useCallback, useEffect, useMemo, useState } from 'react';

import { autoUpdate, flip, offset, shift, size, type UseFloatingOptions } from '../../../libs/floating-ui';

import type { ComboboxOption } from './combobox.model';

const itemToString = (item: ComboboxOption | null) => (item ? item.value : '');

const makeHandleOnInputKeyDown = (callback: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        callback();
    }
};

const comboboxFloatingConfig: UseFloatingOptions = {
    strategy: 'fixed',
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
        offset(({ placement }) => {
            if (placement.startsWith('top')) return 6;
            return -2;
        }),
        flip({
            fallbackPlacements: ['top-start'],
        }),
        size({
            apply: ({ rects, elements }) => {
                elements.floating.style.width = `${rects.reference.width}px`;
            },
        }),
        shift({ padding: 4 }),
    ],
};

type UsePropStateProps = {
    options: ComboboxOption[];
    value?: string;
    createdItemProps?: {
        description?: string;
        label?: string;
    };
    onInputChange?: (value: string) => void;
};

const useCreatableCombobox = ({ options, value, createdItemProps, onInputChange }: UsePropStateProps) => {
    const [filteredOptions, setFilteredOptions] = useState(options);

    /** support for uncontrolled and controlled input value */
    const [inputValue, setInputValue] = useState(value ?? '');
    useEffect(() => {
        if (value !== undefined) {
            setInputValue(value);
        }
    }, [value]);

    /** update options when `options` prop changes */
    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        const values = filteredOptions.map(option => option.value);

        /** if options doesn't include a valid value */
        if (value && !values.includes(value)) {
            /** add the "created" option  */
            setFilteredOptions([
                ...filteredOptions,
                { value, label: createdItemProps?.label ?? value, description: createdItemProps?.description },
            ]);
        }
        /** whenever (just) the value changes */
    }, [value]);

    const selectedOption = useMemo(
        () => filteredOptions.find(option => option.value === value),
        [filteredOptions, value],
    );

    const canCreate = !!inputValue && inputValue !== value;

    const handleOnInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setInputValue(value);
            onInputChange?.(value);
            if (value) {
                const lowerCaseValue = value.toLowerCase().trim();

                /** filter options when user types a chracter/value */
                setFilteredOptions(
                    options.filter(
                        opt =>
                            /** check for string match in value, label, or description */
                            opt.value.toLowerCase().includes(lowerCaseValue) ||
                            opt.label.toLowerCase().includes(lowerCaseValue) ||
                            opt.description?.toLowerCase().includes(lowerCaseValue),
                    ),
                );
            } else {
                /** reset filtered options */
                setFilteredOptions(options);
            }
        },
        [options, onInputChange],
    );

    return {
        filteredOptions,
        setFilteredOptions,
        selectedOption,
        inputValue,
        setInputValue,
        canCreate,
        handleOnInputChange,
    };
};

export { comboboxFloatingConfig, itemToString, makeHandleOnInputKeyDown, useCreatableCombobox };

import { FieldValues, useController } from 'react-hook-form';

import { CombineRefWithProps } from '../../../models';
import { Field } from '../field';
import { FieldOrientationOptions } from '../field/context';
import { MultiSelect, MultiSelectItem, MultiSelectProps, MultiValue } from '../multi-select';

import { FormFieldWithControllerProps } from './form.model';

type FormMultiSelectProps<T extends FieldValues> = FormFieldWithControllerProps<T, MultiSelectProps> &
    FieldOrientationOptions<true>;

export declare function FormMultiSelectComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLButtonElement, FormMultiSelectProps<T>>,
): JSX.Element;

export const FormMultiSelect = (props: FormMultiSelectProps<any>) => {
    const {
        options,
        name,
        label,
        errorMessage,
        placeholder = '',
        hideErrorMessage = false,
        helpText,
        fullWidth,
        control,
        required,
        invalid,
        disabled,
        defaultValue,
        size,
        orientation,
        ...rest
    } = props;

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });

    const findOptionFn = (val: string) => options?.find((opt: MultiSelectItem) => opt.value == val);

    return (
        <Field
            size={size}
            orientation={orientation}
            invalid={!!error?.message}
            fullWidth={fullWidth}
            required={required}
            id={name}
        >
            <Field.Orientation>
                {label ? <Field.Label>{label}</Field.Label> : null}
                <MultiSelect
                    name={name}
                    placeholder={placeholder}
                    value={field.value?.map(findOptionFn)}
                    onChange={(selected: MultiValue<MultiSelectItem>) => {
                        const transformedValues = selected?.map((opt: MultiSelectItem) => opt.value);
                        field.onChange(transformedValues);
                    }}
                    options={options}
                    {...rest}
                />
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{errorMessage ?? error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
};

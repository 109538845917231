import ReactSelect, {
    ActionMeta,
    ClearIndicatorProps,
    components as Components,
    ContainerProps,
    DropdownIndicatorProps,
    MultiValue,
} from 'react-select';

import { AnyObj } from '@blockworks/platform/typescript';

import { mapClassNamesToSlots, TVStyleProps } from '../../../style-system';
import { ChevronSelectorVerticalIcon, XCloseIcon } from '../../icon';
import { SimpleFieldOptions, useFieldProps } from '../field/context';

import { multiSelectStyles, overrideStyles } from './multi-select.styles';

type MultiSelectItem = {
    value: string;
    label: string;
    color?: string;
};

type MultiSelectRootProps = {
    name: string;
    required?: boolean;
    defaultValue?: MultiValue<unknown>[];
    value?: MultiValue<unknown>[];
    disabled?: boolean;
    onChange?: (newValue: MultiValue<any>, actionMeta?: ActionMeta<any>) => void;
};

type MultiSelectProps = {
    options: MultiSelectItem[];
    placeholder?: string;
    id?: string;
} & MultiSelectRootProps &
    SimpleFieldOptions &
    TVStyleProps<typeof multiSelectStyles>;

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
    return (
        <Components.DropdownIndicator {...props}>
            <ChevronSelectorVerticalIcon color="interact" size={1} />
        </Components.DropdownIndicator>
    );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
    return (
        <Components.ClearIndicator {...props}>
            <XCloseIcon color="interact" size={1} />
        </Components.ClearIndicator>
    );
};

const SelectContainer = (rootProps: AnyObj) =>
    function Container(props: ContainerProps<any, true>) {
        return <Components.SelectContainer {...props} {...rootProps} />;
    };

const NoOpComponent = () => null;

const MultiSelect = ({
    options,
    defaultValue,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    disabled,
    required,
    invalid,
    id,
    classNames: classNamesProp,
    size: sizeProp = 'md',
}: MultiSelectProps) => {
    const {
        fieldProps,
        rootProps,
        size = sizeProp,
    } = useFieldProps<HTMLButtonElement>({
        readOnly,
        disabled,
        required,
        invalid,
        id,
    });

    const {
        base,
        control,
        input,
        valueContainer,
        placeholder: placeHolderClassNames,
        indicatorsContainer,
        button,
        multiValue,
        multiValueLabel,
        multiValueRemove,
    } = mapClassNamesToSlots(multiSelectStyles, {
        classNames: classNamesProp,
        size,
    });

    return (
        <ReactSelect
            isMulti
            options={options}
            defaultValue={defaultValue}
            placeholder={placeholder}
            isDisabled={fieldProps.disabled || fieldProps.readOnly}
            required={fieldProps.required}
            name={name}
            value={value}
            onChange={onChange}
            menuPortalTarget={document.body}
            menuPlacement="bottom"
            menuPosition="absolute"
            styles={overrideStyles}
            classNames={{
                input: () => input,
                control: () => control,
                container: () => base,
                valueContainer: () => valueContainer,
                placeholder: () => placeHolderClassNames,
                indicatorsContainer: () => indicatorsContainer,
                clearIndicator: () => button,
                dropdownIndicator: () => button,
                multiValueLabel: () => multiValueLabel,
                multiValue: () => multiValue,
                multiValueRemove: () => multiValueRemove,
            }}
            components={{
                SelectContainer: SelectContainer(rootProps),
                DropdownIndicator,
                ClearIndicator,
                IndicatorSeparator: NoOpComponent,
            }}
        />
    );
};

export { MultiSelect };
export type { MultiSelectItem, MultiSelectProps, MultiSelectRootProps, MultiValue };
